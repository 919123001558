.execution-message-container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .execution-controls {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    margin-top: 4px;

    .active-session-controls-button {
      svg {
        fill: currentColor;
        vertical-align: -0.125em; // This helps center the icon vertically
      }
    }
  }

  .setup-state-container {
    margin-bottom: 8px;

    .ant-typography {
      margin-bottom: 4px;
    }

    .animated-ellipsis::after {
      content: ".";
      animation: ellipsis 1.5s infinite;
    }
  }
}

@keyframes ellipsis {
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
  100% {
    content: ".";
  }
}

.message-cta-container {
  display: flex;
  justify-content: flex-end;
}
