@import "../constants.scss";

.grayed-out {
  opacity: 0.5;
}

.message-buttons {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}

.message-button {
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 6px;
  color: #e6e6e6;
  height: 28px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; // Center the icon
  gap: 4px;
  transition: all 0.15s ease;
  border: none;
  appearance: none;
}

.message-button > button {
  @extend .message-button;
  opacity: 0.2;
}

.edit-button:hover {
  background-color: rgba(24, 144, 255, 0.35);
}

.delete-button:hover {
  background-color: rgba(220, 38, 38, 0.8);
}

.message-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-loading-icon {
  font-size: 2em;
}

.turn-changes-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px;
}

.info-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-left: 16px;
  color: #999;
  font-size: 14px;
  background-color: transparent;
}

.credits-message {
  display: flex;
  align-items: center;
  color: #999;
  background-color: transparent;
  justify-content: flex-end;
  font-size: 13px;
  margin: 0 16px;
  padding: 2px 0;
}

.agent-message:has(.info-message),
.info-message {
  background-color: transparent;
}

.message-cta-container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.message-cta {
  background-color: transparent;
  border: 1px solid #6e6e6e;
  box-shadow: none;
  font-size: 14px;
  color: #999;
}
